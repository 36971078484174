<template>
  <div class="examine-main yt-flex-layout">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb ref="breadcrumb" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>考试考生</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="exam_body">
      <div class="exam_card yt-flex-layout">
        <div class="search__style yt-flex-layout">
          <el-input
            @keyup.enter.native="toSearch"
            style="margin-right: 12px;"
            v-model="payload.examName"
            class="yt-input__style"
            placeholder="通过考试名称模糊搜索"
            size="small"
          >
            <template slot="suffix"><YTIcon href="#icon-sousuo"/></template>
          </el-input>
          <el-select v-model="payload.external" clearable style="margin-right: 12px;" placeholder="考试类型" @change="toSearch">
            <el-option v-for="(item, index) in ['内部评测', '外部招聘']" :key="item" :label="item" :value="index"> </el-option>
          </el-select>
          <el-date-picker
            @change="toSearch"
            v-model="timeDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="yt-table">
          <el-table :data="list" height="100%" @sort-change="handleChange">
            <el-table-column
              v-for="(e, eIndex) in columns"
              :key="eIndex"
              :sortable="e.sortable"
              :prop="e.key"
              :label="e.label"
              :align="e.align || 'left'"
            >
              <template slot-scope="scope">
                <div v-if="e.key === 'startTime'">{{ $formatTime(scope.row[e.key], 'yyyy-MM-dd hh:mm:ss') }}</div>
                <div v-else-if="e.key === 'submitTime'">{{ $formatTime(scope.row[e.key], 'yyyy-MM-dd  hh:mm:ss') }}</div>
                <div v-else-if="e.key === 'examType'">
                  <span class="type-span__style" :style="{ background: bgColor[scope.row[e.key]], color: '#fff' }">{{
                    scope.row[e.key] | getType
                  }}</span>
                </div>
                <div v-else-if="e.key === 'duration'">{{ scope.row[e.key] | setTime }}</div>
                <div v-else-if="e.key === 'examScore'">{{ scope.row[e.key] ? scope.row[e.key] + '分' : '—' }}</div>
                <div
                  v-else-if="e.key === 'score'"
                  :class="
                    Number(scope.row['examScore']) * Number(scope.row['passScore']) >= Number(scope.row[e.key])
                      ? 'red-color'
                      : Number(scope.row['examScore']) * Number(scope.row['passScore']) < Number(scope.row[e.key])
                      ? 'green-color'
                      : ''
                  "
                >
                  {{ scope.row[e.key] !== undefined && scope.row[e.key] !== null ? scope.row[e.key] : '—' }}
                </div>
                <div v-else>{{ scope.row[e.key] || '—' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="toReport(scope.row)" class="op-btn" type="text" v-text="'查看报告'" />
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="page__style">
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="payload.page + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="payload.size"
            show-elevator
            show-sizer
            show-total
            @on-change="changePage"
            @on-page-size-change="changeSize"
          />
        </div>
      </div>
    </div>
    <PersonalReport ref="personalReport"></PersonalReport>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import { examColumns } from './examineColumns'
import newExamManageApi from '@api/newExamManage'
import PersonalReport from '../list/PersonalReport'
export default {
  name: 'NewExaminee',
  components: { YTIcon, PersonalReport },
  data() {
    return {
      bgColor: ['#448bff', '#FFCC3E'],
      list: [],
      name: null,
      examType: null,
      sort: 'startTime,desc',
      total: 0,
      columns: examColumns,
      timeDate: [],
      payload: {
        page: 0,
        size: 10,
        endTime: null,
        examName: '',
        external: null,
        startTime: null,
        userName: '',
        sort: 'startTime,desc'
      }
    }
  },
  created() {
    this.getList()
  },
  filters: {
    getType(type) {
      if (type === 0) {
        return '内部评测'
      } else {
        return '外部招聘'
      }
    },
    setTime(data) {
      if (data > 0) {
        return `${data}分钟`
      } else if (data === 0) {
        return `小于1分钟`
      } else {
        return `一`
      }
    }
  },
  methods: {
    toSearch() {
      this.payload.page = 0
      this.getList()
    },
    handleChange({ column, prop, order }) {
      // ascending 升序 descending 降序
      // prop => key
      if (order === 'ascending') {
        this.sort = `${prop},asc`
      }
      if (order === 'descending') {
        this.sort = `${prop},desc`
      }
      if (!order) {
        // 默认排序 开始时间的倒序
        this.sort = 'startTime,desc'
      }
      this.getList()
    },
    getList() {
      this.payload.sort = this.sort
      this.payload.startTime = this.timeDate ? this.timeDate[0] : ''
      this.payload.endTime = this.timeDate ? this.timeDate[1] : ''
      newExamManageApi.examineeByExam(this.payload).then(res => {
        this.list = res.res.data
        this.total = res.res.total
      })
    },
    changePage(page) {
      this.payload.page = page - 1
      this.getList()
    },
    changeSize(size) {
      this.payload.page = 0
      this.payload.size = size
      this.getList()
    },
    toReport(row) {
      let payload = { departmentIds: [], examId: row.examId, tagIds: [], userIds: [row.userId] }
      this.$refs.personalReport.open(payload)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
@import '../../../../theme/variables';
.examine-main {
  width: 100%;
  //min-width: 1100px;
  flex-direction: column;
  height: 100%;
  background: #f3f4f7;
  .bread_style {
    align-items: center;
    height: 44px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .exam_body {
    height: 100%;
    padding: 10px;
  }
  .exam_card {
    flex-direction: column;
    height: 100%;
    padding: 10px 20px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    .search__style {
      justify-content: flex-end;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        &::placeholder {
          .font(14px, Regular, #999);
        }
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
      ::v-deep .el-range__icon {
        line-height: 26px;
      }
      ::v-deep .el-range-separator {
        line-height: 26px;
      }
      ::v-deep .el-date-editor {
        .el-range__close-icon {
          line-height: 26px;
        }
        .el-range-input {
          &::placeholder {
            .font(14px, Regular, #999);
          }
        }
      }
    }
    .yt-input__style {
      width: 234px;
    }
    .yt-table {
      height: calc(100% - 90px);
      margin-top: 10px;
      .op-btn {
        .font(12px, Regular, #2276ff) !important;
      }
      .type-span__style {
        .font(12px, Regular, #fff);
        border-radius: 13px;
        padding: 5px 15px;
      }
    }
    .page__style {
      padding: 0 20px;
    }
  }
}
.green-color {
  color: #4ce47a;
}
.red-color {
  color: #ff5050;
}
</style>
