const examColumns = [
  {
    label: '姓名',
    key: 'userName',
    align: 'left',
    width: ''
  },
  {
    label: '考试名称',
    key: 'examName',
    align: 'left',
    width: ''
  },
  {
    label: '考试用途',
    key: 'examType',
    align: 'center',
    width: ''
  },
  {
    label: '开始时间',
    key: 'startTime',
    align: 'left',
    sortable: true,
    width: ''
  },
  {
    label: '完成时间',
    key: 'submitTime',
    align: 'left',
    sortable: true,
    width: ''
  },
  {
    label: '考试用时',
    key: 'duration',
    align: 'left',
    sortable: true,
    width: ''
  },
  {
    label: '试卷满分',
    key: 'examScore',
    align: 'left',
    width: ''
  },
  {
    label: '成绩',
    key: 'score',
    align: 'left',
    sortable: true,
    width: ''
  }
]
export { examColumns }
